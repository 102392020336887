import React, { useState, useEffect } from 'react';
import { ImageListItem ,Container, Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';


// Import images
import image1 from './assets/images/image1.png';
import image2 from './assets/images/image2.png';
import image3 from './assets/images/image3.png';
import teaser from './assets/images/teaser.png';

import nusLogo from './assets/images/branding/nus-logo.png';
import ahlabLogo from './assets/images/branding/ahl-logo.png';

import hexagon from './assets/images/hexagon.png';

import logoSrc from './assets/images/Stakco_logo_2.0.png';

import gift from './assets/images/applications/giftAppeal.webp';
import niche from './assets/images/applications/nicheAppeal.webp';
import brand from './assets/images/applications/brandAwareness.webp';
import mental from './assets/images/applications/mentalWellbeing.webp';

import './App.css';

// Styled component for the logo
const StyledLogo = styled('img')(({ rotateDegree }) => ({
  height: '100%',
  width: 'auto',
  transform: `rotate(${rotateDegree}deg)`, // Apply rotation
  transition: 'transform 0.1s ease-out',  // Smooth transition
}));


// Define rotation animations
const rotateSlow = keyframes`
  0% {
    transform: rotate(0deg);
  }
    50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateMedium = keyframes`
  0% {
    transform: rotate(0deg);
  }
    50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateFast = keyframes`
  0% {
    transform: rotate(0deg);
  }
    50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Styled container for the images
const StyledImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '90vw',  // Adjust width relative to the viewport
  height: '90vw', // Maintain a square aspect ratio
  maxWidth: '500px',  // Limit max size
  maxHeight: '500px',  // Limit max size
  borderRadius: '50%',
  margin: '0 auto',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: '80vw',
    height: '80vw',
    maxWidth: '350px',
    maxHeight: '350px',
  },
}));


const StyledRotatingImage = styled('img')(({ animation, theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  top: 0,   // Adjusted to maintain proper positioning
  left: 0,
  animation: `${animation} linear`,
  opacity: 0.8,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
}));

const StyledBackplate = styled('img')(({ animation, theme }) => ({
  position: 'absolute',
  width: '120%',  // Slightly larger than the container for a consistent look
  height: 'auto',
  top: '-10%',
  left: '-10%',
  opacity: 0.8,

  [theme.breakpoints.down('sm')]: {
    width: '140%',
    top: '-20%',
    left: '-20%',
  },
}));


function App() {

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleOrientation = (event) => {
      // if (event.alpha !== null) {
      //   const heading = event.alpha;  // alpha provides the compass heading in degrees
      //   setRotation(heading);
      // }
      const { alpha } = event;
      setRotation(alpha);
    };

    // // Add event listener for device orientation
    // window.addEventListener('deviceorientation', handleOrientation);

    // // Cleanup listener on component unmount
    // return () => {
    //   window.removeEventListener('deviceorientation', handleOrientation);
    // };

    const requestPermission = async () => {
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        try {
          const permission = await DeviceOrientationEvent.requestPermission();
          if (permission === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation);
          }
        } catch (error) {
          console.error('DeviceOrientationEvent permission request denied', error);
        }
      } else {
        window.addEventListener('deviceorientation', handleOrientation);
      }
    };

    requestPermission();

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
    
  }, []);

  // Define the data array for ongoing collaborations
  const projects = [
    { client: 'SG Enable', description: 'We are collaborating with SG Enable to create STAKCO artifacts with artworks by differently able artists.' },
    { client: 'AWWA', description: 'We are considering the application of STAKCO puzzles with therapists at AWWA Bedok School to provide learning opportunities during rest times.' },
    { client: 'RISIS', description: 'We are developing a series of designs in collaboration with RISIS.' },
  ];

  // Define the data array for puzzle variations
  const puzzleVariations = [
    {
      title: 'Physical Puzzles',
      variations: [
        { name: 'Paper Puzzles', description: 'Soft type puzzles that can be mounted onto books, cards, fabric, or any soft material.' },
        { name: 'Acrylic Puzzles', description: 'Rigid type puzzles that can be mounted onto walls, glass, desktops, or any hard material.' },
        { name: 'Acrylic Coasters', description: 'Coasters embedded with a STAKCO puzzle, perfect for both decor and function.' },
      ],
    },
    {
      title: 'Digital Puzzles',
      variations: [
        { name: 'Web Puzzles', description: 'Puzzles deployed on websites and web platforms, easily shareable and customizable.' },
        { name: 'Projection Puzzles', description: 'Dynamic puzzles with rotary plates and a rotation tracking system, mountable on hard surfaces with changeable content.' },
      ],
    },
  ];

  return (
    <Container>

      {/* Section 1: Stakco Title, Circle Image, and Slogan */}
      <Box textAlign="center" mt={5} mb={5}>
        
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <ImageListItem style={{ marginRight: '10px', marginTop:'-20px', height: '80px', width: 'auto' }}>
  <StyledLogo
              src={logoSrc}
              alt="Stakco Logo"
              rotateDegree={rotation}
            />
  </ImageListItem>
  <Typography variant="h3" gutterBottom align="left" sx={{ fontWeight: 'bold' }}>
    Stakco
  </Typography>
</div>
        
        <StyledImageContainer>

          <StyledBackplate
            src={hexagon}
              alt="Rotating Image 1"
              className="floating-backplate"
          >
          </StyledBackplate>
          <StyledRotatingImage
            src={image1}
            alt="Rotating Image 1"
            animation={`${rotateSlow} 19s`}
          />
          <StyledRotatingImage
            src={image2}
            alt="Rotating Image 2"
            animation={`${rotateMedium} 15s`}
          />
          <StyledRotatingImage
            src={image3}
            alt="Rotating Image 3"
            animation={`${rotateFast} 12s`}
          />
        </StyledImageContainer>
        <Typography variant="h4" mt={3} align="right" sx={{ 
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
          lineHeight: { xs: '1.2', sm: '1.4', md: '1.6' } 
        }}>
          Enabling <br />Magical <br /> Interactions <br /> on Paper v1
        </Typography>
      </Box>

      {/* Section 2: What is Stakco */}
      <Box mb={5}>
        <Typography variant="h4" gutterBottom  className="section-heading">
          What is Stakco?
        </Typography>
        <Typography variant="h5" sx={{ lineHeight: 1.8 }} className="section-text">
        Stakco presents a patent-pending, revolutionary puzzle experience where art meets innovation. 
        By rotating transparent layers, users reveal hidden images in a dynamic, interactive way. 
        Each puzzle is customizable, offering a unique challenge that captivates the senses and stimulates the mind. 
        Discover the magic of Stakco’s groundbreaking design.        </Typography>

        <Typography variant="h4" className="section-heading" gutterBottom sx={{ mt: 3 }}>
          Features
        </Typography>
        <Typography variant="h6" sx={{ lineHeight: 1.8 }}>
          • Unlimited rotatable layers (min. 2 layers).<br />
          • Any puzzle shapes, designs, and sizes are possible.<br />
          • Customizable difficulty levels suitable for everyone, from beginners to experts and young to old.<br />
          • Available in both physical and digital options for diverse and enriching experiences.
        </Typography>
      </Box>

      {/* Section 3: Patent and IP */}
      <Box mb={5}>
        <Typography variant="h4" gutterBottom className="section-heading">
          Patent and IP
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
          Patent pending: Cooray, B. S. & Nanayakkara, S. C. Transparent Rotating Composite Puzzle Game with Image Processing Software.
          Singapore Provisional Patent Application No. 10202302289U. Filed August 13th, 2023.
        </Typography>
      </Box>

      {/* Section 4: Current State of Technology */}
      <Box mb={5}>
        <Typography variant="h4" gutterBottom className="section-heading" >
          Current State of Technology
        </Typography>
        <Box mb={3} sx={{opacity: 0.8}}>
          <img
            src={teaser}
            alt="Current Technology"
            width="100%"
            style={{ borderRadius: '8px' }}
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: 0,
            overflow: 'hidden',
            maxWidth: '100%',
            background: '#000',
            borderRadius: '20px',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)', // Smooth shadow for fade effect
            maskImage: 'radial-gradient(circle, white 70%, transparent 100%)' // Smooth fade effect into transparency
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/YmCMtQ8BqS8"
            title="Stakco Demo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '20px', // Apply the same border-radius to the iframe
            }}
          />
        </Box>

      </Box>

      {/* Section 4.5: Potential Applications */}
      <Box mb={5}>
        <Typography variant="h4" gutterBottom className="section-heading" >
          Potential Applications
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
              <CardContent>
                <img
                  src={gift}
                  alt="Gift Appeal"
                  style={{ width: '100%', borderRadius: '4px', marginBottom: '16px' }}
                />
                <Typography variant="h6" gutterBottom>
                  Gift Appeal
                </Typography>
                <Typography variant="body2">
                  Personalized STAKCO puzzles could make for great gifts. People often seek out unique and thoughtful gifts for special occasions, and a distinctive STAKCO puzzle can fit the bill perfectly.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
              <CardContent>
                <img
                  src={niche}
                  alt="Niche Appeal"
                  style={{ width: '100%', borderRadius: '4px', marginBottom: '16px' }}
                />
                <Typography variant="h6" gutterBottom>
                  Niche Appeal
                </Typography>
                <Typography variant="body2">
                  Unique STAKCO puzzles can cater to specific niche markets. Whether it is a puzzle that needs to be based on a specialized theme (e.g., a photo from a personal collection, unique scenery, etc.) or be of a specific level of complexity, STAKCO can cater to these niche interests and create a loyal customer base.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
              <CardContent>
                <img
                  src={brand}
                  alt="Brand Awareness"
                  style={{ width: '100%', borderRadius: '4px', marginBottom: '16px' }}
                />
                <Typography variant="h6" gutterBottom>
                  Brand Awareness
                </Typography>
                <Typography variant="body2">
                  STAKCO's uniqueness can be a powerful marketing tool. It gives you a story to tell, something to showcase on social media, and a reason for word-of-mouth recommendations.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
              <CardContent>
                <img
                  src={mental}
                  alt="Mental Wellbeing"
                  style={{ width: '100%', borderRadius: '4px', marginBottom: '16px' }}
                />
                <Typography variant="h6" gutterBottom>
                  Mental Wellbeing
                </Typography>
                <Typography variant="body2">
                  The STAKCO experience can offer a creative challenge to keep the brain active. Research has shown that engaging in jigsaw puzzles can reduce stress and be a protective factor for cognitive aging.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <a href="https://www.frontiersin.org/articles/10.3389/fnagi.2018.00299/full" target="_blank" rel="noopener noreferrer">
                    Read More
                  </a>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Section 5: Ongoing Collaborations */}
      <Box mb={5}>
        <Typography variant="h4" gutterBottom className="section-heading">
          Current Collaborations
        </Typography>
        <Grid container spacing={3}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#2E3B55' }}>
                    {project.client}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Section 6: Puzzle Modes */}
      <Box mb={5}>
        <Typography variant="h4" gutterBottom className="section-heading">
          Puzzle Modes
        </Typography>
        <Grid container spacing={3}>
          {puzzleVariations.map((category, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
                <CardContent>
                  <Typography variant="h5" gutterBottom sx={{ color: '#2E3B55' }}>
                    {category.title}
                  </Typography>
                  {category.variations.map((variation, i) => (
                    <Box key={i} mb={2}>
                      <Typography variant="h6" sx={{ color: '#2E3B55' }}>
                        {variation.name}
                      </Typography>
                      <Typography variant="body2">
                        {variation.description}
                      </Typography>
                    </Box>
                  ))}
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    All puzzles feature unlimited rotatable layers, customizable designs and sizes, and difficulty levels to suit all ages and expertise levels.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Section 7: Current Research */}
      <Box mb={5}>
  <Typography variant="h4" gutterBottom className='section-heading'>
    Ongoing Research
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.8, color: '#E0E0E0' }}>
    Our research team is continuously exploring innovative ways to expand the STAKCO puzzle experience. Here are some of the exciting projects we're currently working on:
  </Typography>
  <ul style={{ lineHeight: 1.8, color: '#E0E0E0', marginTop: '20px' }}>
    <li>
      Exploring novel methods to create dynamic puzzles using segmented multimedia content, enhancing user interaction through layered manipulations.
    </li>
    <li>
      Investigating various interface modalities to enhance digital puzzle experiences, including unconventional and cutting-edge control systems.
    </li>
    <li>
      Pushing the boundaries of traditional puzzle formats by expanding into multi-dimensional and immersive environments, offering a new layer of complexity.
    </li>
    <li>
      Developing inclusive puzzle formats that utilize alternative sensory inputs to engage users, broadening accessibility and interaction possibilities.
    </li>
  </ul>
</Box>

      {/* section 8 */}
      <Box textAlign="center" mt={5} mb={5}>

      <Card sx={{ boxShadow: 3, color: 'brown', background:'lightyellow', opacity: 0.8}}>
                <CardContent>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={6} sm={4} md={3}>
            <img src={nusLogo} alt="NUS Logo" style={{ width: '100%', maxWidth: '200px' }} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <img src={ahlabLogo} alt="AHLAB Logo" style={{ width: '100%', maxWidth: '200px' }} />
          </Grid>
        </Grid>
                  <Typography variant="h6" color="text.secondary">
                  Based on research work conducted by Augmented Human Lab at the National University of Singapore.


                  </Typography>
                </CardContent>
              </Card>

        


      </Box>

      {/* Section 7: Footer - Contact Us */}
      <Box textAlign="center" mt={5} mb={5}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1">
        Email: stakco@ahlab.org
      </Typography>
      <Box mt={2} display="flex" justifyContent="center" alignItems="center" gap={2}>
        {/* Social Media Links with Icons */}
        <a href="https://www.facebook.com/profile.php?id=61550891646093" target="_blank" rel="noopener noreferrer">
          <FacebookIcon fontSize="large" style={{ color: '#4267B2' }} />
        </a>
        <a href="https://www.instagram.com/stakcos/" target="_blank" rel="noopener noreferrer">
          <InstagramIcon fontSize="large" style={{ color: '#E1306C' }} />
        </a>
        <a href="https://www.youtube.com/@stakcos" target="_blank" rel="noopener noreferrer">
          <YouTubeIcon fontSize="large" style={{ color: '#FF0000' }} />
        </a>
      </Box>
    </Box>

    </Container>
  );
}

export default App;
